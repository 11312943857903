<template>
  <div class="infoPage">
    <div class="topImg">
      <!--      <img :src="dataInfo.sightseeingCover" style="object-fit: cover;" alt="">-->
      <!--      <swiper :options="swiperOptionWZ" class="swiperList">-->
      <!--        <swiper-slide v-for="(item,index) in dataInfo.imgList" class="item-swiper">-->
      <!--          <img :src="item" width="100%" height="100%" style="object-fit: cover;" alt="">-->
      <!--        </swiper-slide>-->
      <!--      </swiper>-->
    </div>

    <div class="conBox">
      <div class="navListBox" v-if="this.$route.query.type=='26'">{{ tType == 'EN' ? 'current position:Experiences With canal>Scenery' : '当前位置：玩转度假区>运河有景' }}  </div>
      <div class="navListBox" v-if="this.$route.query.type=='27'">{{ tType == 'EN' ? 'current position:Experiences With canal>Rooms & Suites' : '当前位置：玩转度假区>运河有宿' }} </div>
      <div class="navListBox" v-if="this.$route.query.type=='28'">{{ tType == 'EN' ? 'current position:Experiences With canal>Dining' : '当前位置：玩转度假区>运河有味' }}  </div>
      <div class="navListBox" v-if="this.$route.query.type=='29'">{{ tType == 'EN' ? 'current position:Experiences With canal>Shops' : '当前位置：玩转度假区>运河有礼' }}  </div>
      <div class="navListBox" v-if="this.$route.query.type=='52'">{{ tType == 'EN' ? 'current position:Experiences With canal>Activities' : '当前位置：玩转度假区>运河有趣' }}  </div>
      <div class="infoBox">
        <div class="base">
          <div class="banner" ref="bannerBox">
            <el-carousel trigger="click">
              <el-carousel-item v-for="item in dataInfo.backgroundImage" :key="item" style="width: 100%;height: 100%">
                <!--                    <img :src="item" alt="" >-->
                <el-image
                    style="width: 100%; height: 100%"
                    :src="item"
                    fit="cover"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="baseInfo">
            <div style="display: flex;">
              <div class="infoTitle">{{ dataInfo.webTitle }}</div>
              <div style="font-size: 22px;color: #F2BE00;margin: 10px; 0 0 10px">
                <span v-if="dataInfo.hotelLevel=='1'">★</span>
                <span v-if="dataInfo.hotelLevel=='2'">★★</span>
                <span v-if="dataInfo.hotelLevel=='3'">★★★</span>
                <span v-if="dataInfo.hotelLevel=='4'">★★★★</span>
                <span v-if="dataInfo.hotelLevel=='5'">★★★★★</span>
              </div>
            </div>

            <div class="bigTags">
              <div class="item" v-for="(item,index) in dataInfo.tags">{{ item }}</div>
            </div>
            <div class="infoDesc">{{ dataInfo.webDesc }}</div>
            <div class="infoNum" v-if="tType == 'CH'">访问量：&nbsp;{{ dataInfo.totalVisitNum || 0 }}&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div class="infoNum" v-if="tType == 'EN'">Visits：&nbsp;{{ dataInfo.totalVisitNum || 0 }}&nbsp;&nbsp;&nbsp;&nbsp;</div>

            <el-divider></el-divider>
            <div>
              {{ tType == 'EN' ? 'place' : '地点' }}：{{ dataInfo.hotelAddress||'' }}
            </div>
            <div id="map" class="map_box"></div>
          </div>
        </div>
        <!--        <div class="title">-->
        <!--          <div class="tit">{{ dataInfo.webTitle }}</div>-->
        <!--&lt;!&ndash;          <div class="time">{{ dataInfo.sightseeingCreateTime || '' }}</div>&ndash;&gt;-->
        <!--        </div>-->
        <!--        <div class="titleDate" style="display: flex;justify-content: space-between;align-items: center">-->
        <!--&lt;!&ndash;          <div class="tags"><span v-for="(item,index) in dataInfo.tags">{{item}}</span></div>&ndash;&gt;-->
        <!--          <div>-->
        <!--            <div class="num" v-if="tType == 'CH'">访问量：&nbsp;{{ dataInfo.totalVisitNum || 0 }}&nbsp;&nbsp;&nbsp;&nbsp;发布时间：&nbsp;{{ dataInfo.sightseeingCreateTime || '' }}</div>-->
        <!--            <div class="num" v-if="tType == 'EN'">Visits：&nbsp;{{ dataInfo.totalVisitNum || 0 }}&nbsp;&nbsp;&nbsp;&nbsp;release time：&nbsp;{{ dataInfo.enCreateTime || '' }}</div>-->
        <!--          </div>-->
        <!--          <div v-if="this.$route.query.type=='26'" style="max-width: 55%">-->
        <!--            {{ tType == 'EN' ? 'place' : '地点' }}：{{ dataInfo.address }}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="X"></div>-->
        <div class="text" v-html="dataInfo.webContent||''">
          <!--          {{ dataInfo.sightseeingContent }}-->
        </div>
      </div>
      <div class="configBox">
        <div class="box1" v-if="this.$route.query.type=='1'">
          <div class="title">{{ tType == 'EN' ? 'Facility services' : '设施服务' }}</div>
          <div class="fwItem" v-for="(item,index) in (dataInfo.gyhHotelserviceList||[])">
            <div class="minTitle">{{ tType == 'EN' ? item.enName : item.name }}</div>
            <div class="minList">
              <div class="minItem" v-for="(items,indexs) in item.children">
                <i class="el-icon-circle-check"></i>{{ tType == 'EN' ? items.enName : items.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="box1" v-else>
          <div class="title">{{ tType == 'EN' ? 'Housing facilities' : '房屋设施' }}</div>
          <div class="fwItem" v-for="(item,index) in (dataInfo.gyhHotelbbservices||[])">
            <div class="minTitle">{{ tType=='EN'? item.enName : item.name }}</div>
            <div class="minList">
              <div class="minItem" v-for="(items,indexs) in item.children">
                <i class="el-icon-circle-check"></i>{{ tType=='EN'? items.enName : items.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="box1" v-if="this.$route.query.type=='1'">
          <div class="title">{{ tType == 'EN' ? 'Required reading for reservation' : '订房必读' }}</div>
          <div class="richText" v-if="tType=='EN'" v-html="dataInfo.enReading||''">
          </div>
          <div class="richText" v-else v-html="dataInfo.reading||''">
          </div>
        </div>
        <div class="box2" v-if="this.$route.query.type=='1'">
          <div class="title">{{ tType == 'EN' ? 'Hotel Policy' : '酒店政策' }}</div>
          <div class="ZCitem">
            <div class="left">
              <img src="../../../assets/img/iconTime.png" alt="">
              {{ tType == 'EN' ? 'Entry and departure time' : '入离时间' }}
            </div>
            <div class="right">
              <div>{{ dataInfo.checkinTime || '' }}</div>
            </div>
          </div>
          <div class="ZCitem">
            <div class="left">
              <img src="../../../assets/img/iconXH.png" alt="">
              {{ tType == 'EN' ? 'Children and extra beds' : '儿童及加床' }}
            </div>
            <div class="right">
              <div v-if="tType=='EN'">{{ dataInfo.enChildren }}</div>
              <div v-else>{{ dataInfo.children }}</div>
            </div>
          </div>
          <div class="ZCitem">
            <div class="left">
              <img src="../../../assets/img/iconCWG.png" alt="">
              {{ tType == 'EN' ? 'Pets' : '宠物' }}
            </div>
            <div class="right">
              <div v-if="tType=='EN'">{{ dataInfo.ispets == '0' ? 'Pets can be carried' : 'Pets are allowed. Pets are not allowed' }}</div>
              <div v-else>{{ dataInfo.ispets == '0' ? '可携带宠物' : '不可携带宠物' }}</div>
            </div>
          </div>
          <div class="ZCitem">
            <div class="left">
              <img src="../../../assets/img/iconZC.png" alt="">
              {{ tType == 'EN' ? 'breakfast' : '早餐' }}
            </div>
            <div class="right" style="line-height: 24px;">
              <div v-if="tType=='EN'">{{ dataInfo.isbreakfast == '0' ? 'Including breakfast' : 'Breakfast not included' }} </div>
              <div v-else>{{ dataInfo.isbreakfast == '0' ? '含早餐' : '不含早餐' }} </div>
              <div v-if="dataInfo.isbreakfast == '0'">
                <div class="ZCtext" v-if="dataInfo.price">{{ tType == 'EN' ? 'Price' : '价格' }}：{{ dataInfo.price||'' }}</div>
                <div class="ZCtext" v-if="dataInfo.breakfastTime">{{ tType == 'EN' ? 'Breakfast time' : '早餐时间' }}：{{ dataInfo.breakfastTime||'' }}</div>
                <div class="ZCtext" v-if="dataInfo.varieties">{{ tType == 'EN' ? 'Breakfast type' : '种类' }}：{{ dataInfo.varieties||'' }}</div>
                <div class="ZCtext" v-if="dataInfo.form">{{ tType == 'EN' ? 'form' : '形式' }}：{{ dataInfo.form||'' }}</div>
              </div>

            </div>
          </div>
          <div class="ZCitem">
            <div class="left">
              <img src="../../../assets/img/iconDR.png" alt="">
              {{ tType == 'EN' ? 'Age limit' : '年龄限制' }}
            </div>
            <div class="right">
              <div v-if="tType=='EN'">{{ dataInfo.enAgerule }}</div>
              <div v-else>{{ dataInfo.agerule }}</div>
            </div>
          </div>
          <div class="ZCitem">
            <div class="left">
              <img src="../../../assets/img/iconMoneyType.png" alt="">
              {{ tType == 'EN' ? 'Payment method' : '支付方式' }}
            </div>
            <div class="right" style="border-bottom: none">
              <div style="display: flex">
                <div class="img" v-for="(item,index) in dataInfo.payTypeVo">
                  <img :src="item.img" alt="" style="object-fit: contain">
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="Dynamic">
        <div class="title">
          <div class="text" v-if="tType == 'CH'">
            其他推荐
          </div>
          <div class="text" v-if="tType == 'EN'">
            Other recommended
          </div>
          <div class="lookAll">
            <!--            查看更多-->
            <!--            <span class="el-icon-arrow-left"></span>-->
            <!--            <span class="el-icon-arrow-right" style="margin-right: 0;"></span>-->
          </div>
        </div>
        <div class="DyList">
          <span class="leftBtn el-icon-arrow-left DynamicLeft"></span>
          <span class="rightBtn el-icon-arrow-right DynamicRight"></span>
          <swiper :options="swiperOptionOther">
            <swiper-slide v-for="(item,index) in otherlist" class="item-swiper">
              <div class="item" @click="goInfo(item)">
                <div class="img scale">
                  <img :src="item.hotelCover" alt="">
                  <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
                </div>
                <div class="con">
                  <div class="tit">
                    <div class="box-1-1-1-1">{{ item.webTitle }}</div>
                    <div class="box-1-1-1-2">
                      <span v-if="item.hotelLevel=='1'">★</span>
                      <span v-if="item.hotelLevel=='2'">★★</span>
                      <span v-if="item.hotelLevel=='3'">★★★</span>
                      <span v-if="item.hotelLevel=='4'">★★★★</span>
                      <span v-if="item.hotelLevel=='5'">★★★★★</span>
                    </div>
                  </div>
                  <!--                  <div class="time">-->
                  <!--                    {{ item.sightseeingCreateTime }}-->
                  <!--                  </div>-->
                  <div class="tags"><span v-for="(items,indexs) in item.tags" v-if="indexs<2">{{items}}</span><span v-if="item.tags.length>2">...</span></div>
                  <div class="path">{{ tType == 'EN' ? 'place' : '地点' }}：{{ item.hotelAddress }}</div>
                  <div class="conText">
                    {{ item.webDesc }}
                  </div>
                </div>

              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getSightseeingInfo, getSightseeingList} from '@/api/table'
import {dynamicinfomationList, hotelInfo, hotelList} from "@/api";
import TMap from "@/utils/initMap";
export default {
  name: "indexInfo",
  components:{
  },
  data(){
    return {
      url:'',
      tType:'CH',
      dataInfo:{},
      swiperOptionWZ: {
        // navigation: {
        //   nextEl: '.scenicRight',
        //   prevEl: '.scenicLeft',
        // },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
      },
      otherlist:[],
      swiperOptionOther: {
        navigation: {
          nextEl: '.DynamicRight',
          prevEl: '.DynamicLeft',
        },
        direction: "horizontal", // 竖向滚动
        // autoHeight: true, // 自适应高度
        slidesPerView: "auto", // 记得这里写 auto 不要默认写1哦
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // loop: true,

      },
      otherId:0,
      titleStr:'',
      hotelLatitude: '39.98412',
      hotelLongitude: '116.307484',
      masker:[],
      tMap:[],
      clientHeight:''

    }
  },
  created() {
    this.titleStr = '当前位置：'+ this.$route.matched[0].meta.title +'>' + this.$route.meta.title
    console.log(this.titleStr)
    this.tType = sessionStorage.getItem('textType')
    this.otherId = this.$route.query.id
    this.initMap()
  },
  mounted() {
    // this.initMap()
    // this.$route.query.id
    setTimeout(()=>{
      this.createInfo(this.$route.query.id)
    },500)

    this.getOtherlist(this.$route.query.id)
    this.clientHeight=this.$refs.bannerBox.clientHeight
  },
  methods:{
    createInfo(hotelId){
      hotelInfo(hotelId,{
        // sightseeingType:'26',   // 必填 玩转度假区类型 26运河有景 27运河游素 28运河有味 29运河有礼 52运河有趣
        // sightseeingOnline:'1',   // 是否上线 0否 1是
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.dataInfo=res.data
          // this.dataInfo.tags = res.data.tags.split(',')
          if(this.dataInfo.tags && this.dataInfo.tags.trim().length>0){this.dataInfo.tags = res.data.tags.split(',')}
          this.dataInfo.hotelCreateTime = new Date(this.dataInfo.hotelCreateTime).toLocaleDateString().replace(/\//g, '-')
          if(this.dataInfo.backgroundImage && this.dataInfo.backgroundImage.trim().length>0){this.dataInfo.backgroundImage = res.data.backgroundImage.split(',')}

          // this.dataInfo.imgList = res.data.backgroundImage.split(',')
          // this.totalCount=res.total
          this.hotelLatitude = this.dataInfo.hotelLatitude
          this.hotelLongitude = this.dataInfo.hotelLongitude


          this.$nextTick(()=>{
            this.choose(this.hotelLatitude,this.hotelLongitude)
          })

        }
      })
    },
    getOtherlist(hotelId){
      hotelList({
        hotelId: hotelId
        // hotelType: this.$route.query.type,      // 1 酒店  2民宿
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          this.otherlist=res.rows
          this.otherlist.forEach((item,index)=>{
            if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
          })
          // this.otherlist = []
        }
      })
    },
    // 地图
    initMap(){
      //定义地图中心点坐标
      TMap.init().then((TMap) => {
        this.TXMap = TMap;
        this.tMap = new TMap.Map("map", {
          // center: new TMap.LatLng(this.hotelLatitude, this.hotelLongitude), //设置地图中心点坐标
          center: new TMap.LatLng(39.98412, 116.307484), //设置地图中心点坐标
          zoom: 15, //设置地图缩放级别
          viewMode: "2D",
        });
        this.masker=new TMap.MultiMarker({
          map:this.tMap,
          styles: {  // 点标记样式
            "myStyle": new TMap.MarkerStyle({
              "width": 26,
              "height": 32,
              "src": '/img/mapIcon.png',
              "anchor": { x: 10, y: 10 },
              "opacity": 1
            })
          },
          geometries: [{
            "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            "styleId": 'myStyle',  //指定样式id
            "position": new TMap.LatLng(39.98412, 116.307484),  //点标记坐标位置
            "properties": {//自定义属性
              "title": "marker1"
            }
          }],
        })
      });

    },
    choose(hotelLatitude,hotelLongitude){
      // this.carId=item.id
      // this.initMap(item.latitude,item.longitude)
      TMap.init().then((TMap) => {
        this.tMap.setCenter(new TMap.LatLng(hotelLatitude,hotelLongitude))
        this.masker.updateGeometries([
          {
            "styleId":"myStyle",
            "id": "1",
            "position": new TMap.LatLng(hotelLatitude,hotelLongitude),
          }
        ])
      })


    },
    goInfo(item){
      this.$router.push({path:'/playturn/haveNightInfoNew',query:{id:item.hotelId,type:this.$route.query.type}})
      this.createInfo(item.hotelId)
      this.otherId = item.hotelId
      this.getOtherlist()
    },
    // 每页条数
    handleSizeChange(val) {
      this.per_page=val
      this.createInfo()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page=val
      this.createInfo()
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  min-height: 800px;
  //padding: 0 0 0 55px;
  .topImg{
    width: 100%;
    //height: 800px;
    height: 280px;
    //background: #007B8A ;
    overflow: hidden;
    img{
      width: 100%;
    }
    .swiperList{
      height: 100%;
      width: 100%;
      /deep/.swiper-wrapper{
        display: flex;
      }
      .item-swiper{
        height: 800px;
        overflow: hidden;
        min-width: 100%;
      }
    }
  }
  .conBox{
    width: 1300px;
    //margin: -100px auto 0;
    margin: -270px auto 0;
    padding-bottom: 180px;
    position: relative;
    .navListBox{
      height: 70px;
      //background: #F9F9F9;
      border-radius: 40px 40px 0px 0px;
      font-size: 14px;
      font-family: STSongti-SC-Bold, STSongti-SC;
      font-weight: bold;
      color: #333333;
      line-height: 70px;
      padding-left: 50px;
      span{
        color: #007B8A;
      }
    }
    .infoBox{
      width: 100%;
      background: #FFFFFF;
      margin-bottom: 20px;
      //border-radius: 40px 40px 0px 0px;
      padding: 50px;
      .base{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        overflow: hidden;
        /deep/.banner{
          width: 520px;
          min-height: 520px;
          //background: #0C2480;
          .el-carousel,.el-carousel__container,.el-carousel__container{
            height: 100%;
          }
        }
        .baseInfo{
          width: calc(100% - 560px);
          //height: 520px;
          .infoTitle{
            max-width: calc(100% - 110px);
            font-size: 34px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
            line-height: 48px;
          }
          .infoDesc{
            margin-top: 9px;
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
          }
          .infoNum{
            margin-top: 20px;
            font-size: 14px;
            font-family: STSongti-SC-Bold, STSongti-SC;
            font-weight: bold;
            color: #333333;
            line-height: 14px;

          }
          .map_box{
            width: 100%;
            height: 291px;
            background: #999999;
            margin-top: 30px;
          }
        }
      }
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tit{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          max-width: 80%;
        }
        .time{
          margin: 20px 0;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
        }
      }
      .titleDate{
        margin: 20px 0 20px;
        display: flex;
        align-items: center;
        .tags{
          span{
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            padding: 3px 13px 4px 12px;
            margin-right: 10px;
            margin-bottom: 10px;
            background: rgba(120, 120, 120, 0.1);
            border-radius: 17px;
          }
        }
        .num{
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #333333;
          //margin-left: 30px;
        }
      }
      .X{
        width: 100%;
        height: 1px;
        background: #DDDDDD;
        margin: 0px 0 50px;
      }
      .text{
        //font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        //font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
      .textBox{
        font-size: 14px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-bottom: 32px;
      }
      .imgBox{
        display: flex;
        margin-bottom: 200px;
        .left,.right{
          width: 50%;
          //width: 600px;
          //height: 800px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        //.right{
        //  width: 50%;
        //  background: pink;
        //display: flex;
        //flex-wrap: wrap;
        //.item{
        //  flex: none;
        //  width: 50%;
        //  height: 400px;
        //}
        //}
      }
    }

    .configBox{
      padding: 40px 50px;
      background: #ffffff;
      .box1{
        padding: 0 0 50px 0;
        margin-bottom: 60px;
        border-bottom: 1px dashed #D3D3D3;
      }
      .box2{
        .ZCitem{
          display: flex;
          .left{
            display: flex;
            width: 195px;
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #000000;
            img{
              width: 14px;
              height: 14px;
              margin-right: 10px;
            }
          }
          .right{
            width: calc(100% - 200px);
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            border-bottom: 1px solid #D3D3D3;
            margin-bottom: 20px;
            padding-bottom: 20px;
            .img{
              width: 68px;
              height: 40px;
              margin-right: 20px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .ZCtext{
              margin-top: 8px;
            }
          }
        }
      }
      .title{
        font-size: 20px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #333333;
        margin-bottom: 30px;
      }
      .fwItem{
        margin-bottom: 40px;
        &:nth-last-child(1){
          margin-bottom: 0px;
        }
        .minTitle{
          font-size: 16px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          margin-bottom: 16px;
        }
        .minList{
          display: flex;
          flex-wrap: wrap; // 强制换行
          .minItem{
            width: 20%;
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            margin-top:10px ;
            i{
              margin-right: 10px;
            }
          }
        }
      }
    }

    .Dynamic{
      //background: #F5F5F5;
      padding: 60px 0 0px 0;
      .title{
        width: 1200px;
        margin: 0px auto 30px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        .text{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          span{
            color: #337D8D;
          }
        }
        .lookAll{
          font-size: 20px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #000000;
          cursor: pointer;
          span{
            margin: 0 20px;
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;
            &:hover{
              color: #337D8D;
            }
          }
        }
      }
      .DyList{
        width: 1200px;
        margin: auto;
        position: relative;
        &:hover{
          .leftBtn,.rightBtn{
            display: block;
            transition:all 1000ms ease;
          }
        }
        .leftBtn,.rightBtn{
          display: none;
          position: absolute;
          top: 50%;
          left: 0;
          width: 50px;
          height: 80px;
          background: rgba(0,0,0,0.5);
          z-index: 5;
          text-align: center;
          line-height: 80px;
          border-radius:0 10px 10px 0;
          transform: translate(0, -50%);
          font-size: 25px;
          color: #007B8A;
          transition:all 1000ms ease;
          cursor: pointer;
        }
        .rightBtn{
          border-radius: 10px 0 0 10px;
          left: auto;
          right: 0;
          transform: translate(0, -50%);
        }
        /deep/.swiper-container{
          overflow: hidden;
        }
        /deep/.swiper-wrapper{
          display: flex;
          //justify-content: space-between;
          .item-swiper{
            width: 380px;
            margin-right: 30px;
            &:nth-last-child(1){
              margin-right: 0;
            }
          }
        }
        .item{
          flex: none;
          width: 380px;
          min-height: 450px;
          height: 100%;
          background: #FFFFFF;
          border-radius: 20px;
          overflow: hidden;
          &:hover{
            .img{
              transition:all 1000ms ease;
              //transform: scale(1);
              transform: scale(1.1);
            }
            .con>.tit{
              color: #337D8D;
            }
          }
          .img{
            width: 100%;
            height: 300px;
            position: relative;
            transition:all 500ms ease;
            img{
              width: 100%;
              height: 100%;
            }
            .eye{
              position: absolute;
              top: 16px;
              left: 16px;
              padding: 8px 12px;
              background: #337D8D55;
              border-radius: 5px;
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 600;
              color: #ffffff;
            }
          }
          .con{
            padding: 24px;
            min-height: 125px;
            .tit{
              display: flex;
              .box-1-1-1-1{
                max-width: 70%;
                font-size: 20px;
                font-family: STSongti-SC-Black, STSongti-SC;
                font-weight: 900;
                color: #333333;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1; //行数
                overflow: hidden;
              }
              .box-1-1-1-2{
                font-size: 20px;
                color: #F2BE00;
                margin-left: 10px;
              }
            }
            .time{
              font-size: 14px;
              font-family: STHeitiSC-Medium, STHeitiSC;
              font-weight: 500;
              color: #999999;
              margin: 16px 0;
            }
            .tags{
              margin: 15px 0 10px;
              display: flex;
              align-items: center;
              width: 100%;
              flex-wrap: wrap;
              span{
                background: rgba(0, 123, 138, 0.1);
                border-radius: 20px;
                font-size: 12px;
                font-family: STSongti-SC-Regular, STSongti-SC;
                font-weight: 400;
                color: #007B8A;
                padding: 4px 12px;
                box-sizing: border-box;
                margin-right: 10px;
                margin-bottom: 10px;
              }
            }
            .path{
              margin: 10px 0;
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #333333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1; //行数
              overflow: hidden;
            }
            .conText{
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #999999;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //行数
              overflow: hidden;
            }
          }

        }
      }
    }

  }


}
.bigTags{
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
  .item{
    flex: none;
    font-size: 12px;
    font-family: STSongti-SC-Regular, STSongti-SC;
    font-weight: 400;
    color: #007B8A;
    padding: 3px 13px 4px 12px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: rgba(0, 123, 138, 0.1);
    border-radius: 17px;
  }
}
</style>

